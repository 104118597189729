import {useAuthStore} from "~/stores/auth";

export default defineNuxtRouteMiddleware(async (to) => {
    const app = useNuxtApp()
    const store = await useAuthStore(app.$pinia)

    if (store.user === null) {
        return navigateTo({
            path: '/login',
            query: to.query,
        })
    }

    if (store.user === undefined) {
        await store.initAuth()

        const user = store.user

        if (!user) {
            return navigateTo({
                path: '/login',
                query: to.query,
            })
        }

        if (!user.is_on_free_plan) {
            return navigateTo({
                path: '/billing',
                query: to.query,
            })
        }
    }
})